.carusel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    .arrow {
        width: 5%;
        @media screen and (max-width: 767px) {
            width: 10%;
        }
        div {
            width: auto;
            height: 2rem;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
            @media screen and (max-width: 767px) {
                background-position: center;
            }
        }
        &.right div {
                background-image: url("./img/arrow-right.svg")
        }
        &.left div {
                background-image: url("./img/arrow-left.svg")
        }
    }
    .image {
        width: 22%;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 767px) {
            display: none;
        }
        img {
            width: 50%;
            cursor: pointer;
        }
    }
    .full-story {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 46%;
        animation: fadeIn 3s forwards;
        @keyframes fadeIn  {
            0%   { opacity: 0;}
            100% { opacity:1;}
        }
        @media screen and (max-width: 767px) {
            width: 80%;
        }
        img {
            width: 50%;
            padding-inline-end: 2rem;
        }
        p {
            font-size: 1rem;
            @media screen and (max-width: 767px) {
                font-size: 1.8rem;
            }
        }
        .title {
            margin: 0;
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: 700;
        }
        a {
            font-size: 0.8rem;
            color: #E5989B;
            font-weight: bold;
            text-decoration: none;
            span {
                display: inline-block;
                width: 0.3rem;
                height: 0.3rem;
                border-top: 0.1rem solid #E5989B;
                border-left: 0.1rem solid #E5989B;
                transform: rotate(-45deg);
                margin-inline-start: 1rem;
                &:after {
                    content: "";
                    display: block;
                    width: 0.1rem;
                    height: 1rem;
                    background-color: #E5989B;
                    transform: rotate(-45deg) translate(0.16rem, 0);
                }
            }
        }
    }
}