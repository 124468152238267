.container {
    color: #423657;
    .login {
        display: flex;
        flex-direction: column;
        width: 20%;
        .input {
            margin-bottom: 1vw;
        }
    }
}