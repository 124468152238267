.contact-form {
    background: url("./img/background.svg");
        background-repeat: no-repeat;
        background-position: center;
        padding: 1rem 3rem 2rem;
        background-size: 100%;
        width: 27%;
        margin: 0 auto;
        height: 60%;
        margin-top: 7vw;
    
        form {
            text-align: center;
            padding: 0 4rem;
            div {
                padding-bottom: 0.5rem;
                label {
                    margin-bottom: 0.5rem;
                    text-align: start;
                    display: block;
                }
                input, textarea {
                    background: #FFDED6;
                    border: none;
                    border-radius: 1rem;
                    padding: 0.5rem;
                    width: 100%;
                    &[type="textarea"] {
                        height: 7rem;
                    }
                }
            }
        }
    
        h2 {
            margin: 0.5rem 0;
            p {
                text-align: center;
                color: #423657;
                margin: 0;
                font-size: 2.2rem;
                font-weight: 800;
                text-shadow: 6px 6px 10px rgba(66, 54, 87, 0.31);
        }
    }
    button {
        margin-top: 0.3rem;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 10rem;
        padding: 0.5rem 2.2rem;
        margin-inline-end: 1.8rem;
        text-decoration: none;
        background-color: #423657;
        color: #fff;
        cursor: pointer;
        &:hover {
            background-color: #fff;
            color: #423657;
        }
    }
    .phone {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #423657;
        margin-top: 0.5rem;
        font-weight: bold;
        text-decoration: none;
        img {
            width: 10%;
            padding: 0 0.5rem;
        }
        .number {
            text-decoration: underline;
        }
    }
}