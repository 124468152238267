.donation {
    padding: 14rem 5rem 15rem;
    background-image: url("./img/background.svg");
    text-align: center;
    background-color: #FFF0E7;
    @media screen and (max-width: 767px) { 
        background-position: center;
    }
    .title {
        padding-bottom: 2rem;
        p {
            margin: 0;
            font-size: 1.25rem;
            font-weight: 600;
            padding-bottom: 0.5rem;
            @media screen and (max-width: 767px) { 
                font-size: 2.25rem;
            }
            &.association {
                font-size: 0.8rem;
                @media screen and (max-width: 767px) { 
                    font-size: 1.5rem;
                }
            }
            strong {
                font-size: 2.5rem;
                display: block;
                &.weight {
                    font-weight: 500;
                }
            }
        }
    }
    a {
        font-size: 1rem;
        font-weight: bold;
        border-radius: 10rem;
        padding: 0.5rem 2.2rem;
        margin-inline-end: 1rem;
        border: none;
        color: #423657;
        background-color: #fff;
        cursor: pointer;
        text-decoration: none;
        @media screen and (max-width: 767px) { 
            font-size: 2.5rem;
        }
        &:hover {
            background-color: #423657;
            color: #fff;
        }
    }
}