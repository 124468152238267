.QAndA {
    background: #FFF0E7;
    padding: 5rem 10rem 0rem;
    @media screen and (max-width: 767px) { 
        display: none;
    }
    .content {
        h2 {
            padding-bottom: 4rem;
            p {
                text-align: center;
                color: #423657;
                margin: 0;
                font-size: 2.2rem;
                font-weight: 800;
                text-shadow: 6px 6px 10px rgba(66, 54, 87, 0.31);
                &:last-child {
                    font-weight: 700;
                }
            }
        }
        width: 90%;
        margin: 0 auto;
        .bubbles {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;

            >div {
                background-repeat: no-repeat;
                background-position: center;
                background-size: 70%;
                width: 33%;

                .title {
                    font-weight: bold;
                }

                .text {
                    font-size: 0.8rem;
                }

                @for $i from 0 through 5 {
                    $img: "./img/bubble"+($i)+".svg";

                    &.bubble-#{$i} {
                        // background-image: url($img);
                        width: 33%;
                        height: 20rem;
                        position: relative;
                        img {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            width: 73%;
                        }
                        div {
                            padding: 2rem 0rem;
                            width: 10rem;
                            padding-right: 4rem;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            position: absolute;
                        }
                    }
                }

                &.bubble-0 div {
                    .title {
                        color: #fff;
                    }
                }

                &.bubble-1 div {
                    padding-top: 1rem;
                    padding-right: 3rem;
                    transform: rotate(-4deg);
                }

                &.bubble-2 div {
                    padding-top: 2rem;
                    padding-right: 3.5rem;
                    transform: rotate(7deg);
                }

                &.bubble-3 div {
                    transform: rotate(10deg);
                    padding-top: 1.5rem;
                }

                &.bubble-4 div {
                    padding-right: 4.9rem;
                    .title {
                        color: #fff;
                    }
                }

                &.bubble-5 div {
                    transform: rotate(-8deg);
                    padding-right: 3rem;
                    padding-top: 0.1em;
                }
            }
        }
    }
}