.our-babies {
    position: relative;
    font-size: 0;
    .background {
        position: relative;
        width: 100%;
        background: #FFDED6;
        height: 46rem;
        @media screen and (max-width: 767px) {
            height: 52rem;
        }
    }
    .content-float {
        line-height: 1.18;
        position: absolute;
        top: 1rem;
        width: 90%;
        right: 0;
        left: 0;
        margin: 0 auto;
        h2 {
            text-align: center;
            font-size: 2.2rem;
            font-weight: 700;
            color: #B5838D;
            padding-top: 7rem;
            padding-bottom: 3rem;
            @media screen and (max-width: 767px) {
                font-size: 4.2rem;
                padding: 0;
            }
        }
        .action {
            margin-top: 7rem;
            text-align: center;
            a {
                font-size: 1rem;
                font-weight: bold;
                border-radius: 10rem;
                padding: 0.5rem 2.2rem;
                margin-inline-end: 1rem;
                border: none;
                &:first-child {
                    color: #fff;
                    background-color: #B5838D;
                    &:hover {
                        background-color: transparent;
                    }
                }
                &:last-child {
                    color: #fff;
                    background-color: transparent;

                    &:hover {
                        background-color: #B5838D;
                    }
                }
            }
        }
    }
}