.about {
    background-color: #FFDED6;
    position: relative;

    >div {
        width: 90%;
        margin: 0 auto;

        .ellipse-there {
            width: 20%;
            position: absolute;
            top: 0;
            // right: 4.6rem;
            animation: rightToLeft 3s forwards;
            @keyframes rightToLeft  {
                0%   { right:0;}
                100% { right: 7.7rem;}
            }
        }

        .content {
            position: relative;
            padding: 5rem 10rem;
            @media screen and (max-width: 767px) { 
                padding: 5rem 3rem;
            }
            .title {
                padding-bottom: 1rem;
                p {
                    color: #423657;
                    text-shadow: 6px 6px 10px rgba(66, 54, 87, 0.31);
                    margin: 0;
                    font-size: 2.2rem;
                    padding-bottom: 0.1rem;
                    font-weight: 700;
                    @media screen and (max-width: 767px) { 
                        font-size: 4.2rem;
                        line-height: 4.2rem;
                    }
                    &:first-child {
                        font-weight: 800;
                    }
                }
            }

            .text {
                display: flex;
                flex-direction: row;
                font-size: 1.2rem;
                @media screen and (max-width: 767px) { 
                    font-size: 2rem;
                    flex-direction: column;
                }
                @media screen and (min-width: 767px) { 
                    >div { 
                        width: 50%;
    
                        &:first-child {
                            padding-inline-end: 3rem;
                        }
    
                        &:last-child {
                            padding-inline-start: 3rem;
                        }
                    }
                }
            }
        }
    }
}