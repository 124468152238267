.joining {
    background-color: #FFDED6;
    padding: 5rem 3rem;

    .content {
        h2 {
            p {
                text-align: center;
                color: #423657;
                margin: 0;
                font-size: 2.2rem;
                font-weight: 800;
                text-shadow: 6px 6px 10px rgba(66, 54, 87, 0.31);

                &:last-child {
                    font-weight: 700;
                }
            }
        }

        width: 90%;
        margin: 0 auto;

        .MuiTabs-flexContainer {
            button {
                color: rgba(66, 54, 87, 0.3);
                font-weight: 700;
                font-size: 1.5rem;
            }
        }

        .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
            color: #FFF7F2;
        }

        .accordion {
            background: rgba(255, 240, 231, 0.45);
            border-radius: 1.5rem;
            margin: 1rem;

            &:before {
                display: none;
            }

            &.open {
                background: rgba(255, 240, 231, 0.8);
            }

            .summary {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                padding: 0 0.5rem;

                .title {
                    color: #423657;
                    font-size: 1.2rem;
                    font-weight: 700;
                    margin: 0;

                    .number {
                        font-weight: 800;
                        color: rgba(66, 54, 87, 0.62);
                        padding-inline-end: 0.5rem;
                    }
                }

                .more {
                    color: #7B677C;
                    font-weight: 700;
                    margin: 0;

                    &.open {
                        &:after {
                            content: url('./img/open.svg');
                            padding-inline-start: 0.5rem;
                        }
                    }

                    &.close {
                        &:after {
                            content: url('./img/close.svg');
                            padding-inline-start: 0.5rem;
                        }
                    }
                }
            }

            .text {
                padding: 0 1rem 1rem 1rem;
            }
        }

        .MuiBox-root {
            background: rgba(255, 255, 255, 0.46);

            .MuiTabs-flexContainer {

                .Mui-selected {
                    background-color: #423657;
                    color: #fff;
                }
            }
            form {
                @media screen and (max-width: 767px) { 
                    font-size: 1.8rem;
                }
                label {
                    padding-bottom: 1rem;
                    &.MuiFormControlLabel-root {
                        padding-top: 1.5rem;
                    }
                }
                .names {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: 2rem;
                    @media screen and (max-width: 767px) { 
                        flex-direction: column;    
                    }
                    &.shidduch {
                        justify-content: center;
                        .select {
                            > .radio {
                                background: #423657;
                                border-radius: 3px 6px 2px 4px;
                                padding: 0;
                                &.daughter {
                                    background: #FFDED6;
                                    .check:after {
                                        color: #423657;
                                    }
                                }
                                > span {
                                    padding: 0.7rem;
                                    &.check {
                                        padding: 0 0.4rem;
                                        &:after {
                                            content: "V";
                                            color: #FFDED6;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    >div {
                        display: flex;
                        flex-direction: column;
                        span {
                            padding: 0 1.2rem;
                        }
                        > div {
                            @media screen and (max-width: 767px) { 
                                margin-bottom: 2rem;
                            }
                        }
                    }
                }

                .details {
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 767px) { 
                        flex-direction: column;    
                    }
                    >div {
                        display: flex;
                        flex-direction: column;
                        width: 48%;
                        @media screen and (max-width: 767px) { 
                            width: 100%;
                            margin-bottom: 2rem;
                        }
                    }
                }

                .MuiFormControlLabel-root {
                    .MuiTypography-root{
                        @media screen and (max-width: 767px) {
                            font-size: 1.5rem;
                        }
                    }
                }

                input,
                .MuiSelect-select {
                    background: #FFDED6;
                    border-radius: 3rem;
                    border: none;
                    font-size: 1rem;
                    padding: 0.6rem 0.8rem;
                    @media screen and (max-width: 767px) {
                        padding: 0.6rem 0.4rem;
                    }
                }

                .MuiOutlinedInput-root {
                    fieldset {
                        display: none;
                    }

                    svg {
                        right: unset;
                        left: 1rem;
                    }
                }
                .MuiFormControlLabel-root {
                    margin: 0;
                }
                button {
                    display: block;
                    margin: 0 auto;
                    background: #423657;
                    color: #fff;
                    border-radius: 8rem;
                    font-size: 1.2rem;
                    padding: 0.5rem 2.5rem;
                }
                .error {
                    border: 1px solid red;
                    border-radius: 3rem;
                }
            }
        }
    }
    .sentSucsses {
        padding: 0.5rem;
        p {
            text-align: center;
            margin: 0rem;
            padding-bottom: 1rem;
        }
        button {
            display: block;
            margin: 0 auto;
            background: #423657;
            color: #fff;
            border-radius: 8rem;
            font-size: 1.2rem;
            padding: 0.5rem 2.5rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .MuiPaper-root ul li {
        font-size: 2.5rem;
    }
}