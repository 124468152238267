.salvation {
    .title {
        font-size: 1.2rem;
        font-weight: bold;
    }
    input {
        width: 98%;
    }
    .send {
        margin-top: 1.5rem;
    }
    .salvationStory {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        input {
            width: 5%;
        }
        p {
            margin: 0.2rem 0;
        }
        .delete {
            svg {
                cursor: pointer;
                padding-inline-end: 0.5rem;
            }
        }
    }
}