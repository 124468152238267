@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
    font-size: 1vw;
}

body {
    direction: rtl;
    font-size: 1vw;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.block {
    display: block;
}

.red {
    color: #ff3f31;
}

body .css-ahj2mt-MuiTypography-root {
    font-family: inherit;
}

.paddingTop {
    padding-top: 1rem;
}

@media screen and (max-width: 767px) {
    html {
        font-size: 2vw;
    }  
    body {
        font-size: 1rem;  
    }
}