.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
        width: 15%;
        img {
            width: 100%;
            vertical-align: middle;
        }
    }
    nav {
        display: flex;
        width: 70%;
        justify-content: center;
        font-size: 1.5rem;
        a {
            padding: 0 0.5rem;
            text-decoration: none;
            color: #423657;
            font-weight: 600;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
    .btnDonation {
        width: 15%;
        text-align: left;
        a {
            border-radius: 10rem;
            width: 7rem;
            max-width: 100%;
            height: 1.8rem;
            background: #423657;
            color: #fff;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            line-height: 1.8;
        }
    }
}