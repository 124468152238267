header {
    background: #FFDED6;
    .banner {
        position: relative;
        width: 100%;
    }
    .ellipse-one {
        width: 20%;
        position: absolute;
        // top: 0;
        right: 5%;
        animation: bubble 3s forwards;
        @keyframes bubble  {
            0%   { transform:scale(0.5); opacity:0.0; top: 0rem;}
            50%  { transform:scale(1.2); opacity:0.5;top: -2rem;}
            100% { transform:scale(1.0); opacity:1.0; top: 0rem;}
        }
    }
    .ellipse-two {
        width: 20%;
        position: absolute;
        left: 0;
        animation: bubbleUpDown 3s forwards;
        @keyframes bubbleUpDown  {
            0%   { top:0;}
            40% {top: 40%}
            70% {top: 30%}
            100% { top:40%;}
        }
    }
    .content-float {
        line-height: 1.18;
        position: absolute;
        top: 1rem;
        width: 90%;
        right: 0;
        left: 0;
        margin: 0 auto;
        .content { 
            padding-top: 20rem;
            padding-right: 10rem;
            @media screen and (max-width: 767px) {
                padding: 4rem;
            }
            .title {
                p {
                    font-size: 3.5rem;
                    line-height: 3.5rem;
                    font-weight: bold;
                    text-shadow: 6px 6px 10px rgba(66, 54, 87, 0.31);
                    margin: 0;
                    @media screen and (max-width: 767px) {
                        font-size: 3rem;
                        font-weight: bold;
                    }
                    .source {
                        font-size: 1rem;
                        font-weight: 500;
                        display: inline-block;
                        margin-right: -1rem;
                    }
                    .readMore {
                        color: #B5838D;
                        font-size: 1.5rem;
                        text-decoration: underline;
                        cursor: pointer;
                        &:before {
                            content: "i";
                            padding: 0 0.8rem;
                            background: #B5838D;
                            border-radius: 50%;
                            margin-inline-end: 0.5rem;
                            color: #fff;
                        }
                    }
                }
            }
            p {
                font-size: 1.2rem;
                margin: 1rem 0;
                margin-bottom: 2rem;
                @media screen and (max-width: 767px) {
                    font-size: 2rem;
                    margin: 2rem 0;
                }
            }
            .action {
                a {
                    font-size: 1rem;
                    font-weight: bold;
                    border-radius: 10rem;
                    padding: 0.5rem 2.2rem;
                    margin-inline-end: 1.8rem;
                    text-decoration: none;
                    @media screen and (max-width: 767px) { 
                        font-size: 2rem;
                    }
                }
                a:first-child {
                    background-color: #423657;
                    color: #fff;
                }
                a:last-child {
                    background-color: #fff;
                    color: #423657;
                }
            }
        }
    }
    // height: 35rem;
    // background-image: url("./img/banner.png");
    // background-size: cover;
    // padding: 2rem;
}
.MuiBackdrop-root {
    background-color: #b5838d99;
}
.modal {
    position: relative;
    width: 29rem;
    margin: 0 auto;
    margin-top: 10rem;
    background-image: url("./img/bg-modal.png");
    background-size: 100%;
    background-repeat: no-repeat;
    .close {
        background-image: url("./img/close.svg");
        position: absolute;
        top: 0;
        right: 0;
        width: 2vw;
        height: 2vw;
        background-size: 65%;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .content {
        width: 80%;
        height: 27vw;
        margin: 0 auto;
        padding-top: 6.5vw;
        font-size: 0.8vw;
    }
}