.instructions {
    background: url("./img/background.svg");
    background-position: bottom;
    background-color: #FFDED6;
    padding: 5rem 10rem 9rem;
    @media screen and (max-width: 767px) {
        padding-right: 3rem;
        padding-left: 3rem;
    }
    .content {
        h2 {
            p {
                text-align: center;
                color: #423657;
                margin: 0;
                font-size: 2.2rem;
                font-weight: 800;
                text-shadow: 6px 6px 10px rgba(66, 54, 87, 0.31);
                &:last-child {
                    font-weight: 700;
                }
            }
        }
        width: 90%;
        margin: 0 auto;
        .MuiTabs-flexContainer {
            button {
                color: rgba(66, 54, 87, 0.3);
                font-weight: 700;
                font-size: 1.5rem;
                @media screen and (max-width: 767px) {
                    font-size: 2.5rem;
                }
            }
        }
        .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
            color: #FFF7F2;
        }
        #simple-tabpanel-0, #simple-tabpanel-1 {
            .MuiBox-root {
                @media screen and (max-width: 767px) {
                    padding: 3rem 0;
                }
            }
        }
        .accordion {
            background: rgba(255, 240, 231, 0.45);
            border-radius: 1.5rem;
            margin: 1rem;
            &:before {
                display: none;
            }
            &.open {
                background: rgba(255, 240, 231, 0.8);
            }
            .summary {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                padding: 0 0.5rem;
                .title {
                    color: #423657;
                    font-size: 1.2rem;
                    font-weight: 700;
                    margin: 0;
                    @media screen and (max-width: 767px) {
                        font-size: 2rem;
                    }
                    .number {
                        font-weight: 800;
                        color: rgba(66, 54, 87, 0.62);
                        padding-inline-end: 0.5rem;
                    }
                }
                .more {
                    color: #7B677C;
                    font-weight: 700;
                    margin: 0;
                    span {
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                    &.open {
                        &:after {
                            content: url('./img/open.svg');
                            padding-inline-start: 0.5rem;
                        }
                    }
                    &.close {
                        &:after {
                            content: url('./img/close.svg');
                            padding-inline-start: 0.5rem;
                        }
                    }
                }
            }
            .text {
                padding: 0 1rem 1rem 1rem;
                @media screen and (max-width: 767px) {
                    font-size: 2.5rem;
                }
            }
        }
    }
}